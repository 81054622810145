import React from 'react'
import { useScrollAnim } from "src/components/hooks/hooks"
import { NavTabs, TabContent, TabPane } from "components/anti/tabs/tabs"



export const WFBIncludedMobile = ({ data1, data2 }) => {

    const dataTabs = [
        { name: "Detail Paket", target: "whats-included" },
        { name: "Kebijakan Pembatalan", target: "cancellation-policy" }
    ]

    const [trigger, anim] = useScrollAnim()
    return (
        <section className={`pb-main sc-wfb-included d-block d-md-none`} ref={trigger}>
            <div className="container">
                <NavTabs
                    variant="tabs"
                    scrollable
                    data={dataTabs}
                    className={`justify-content-center ${anim(3)}`}
                />
                <TabContent>
                    <TabPane id="whats-included" className="active">
                        <ul className="list-2-column">
                            {data1?.list.map((list, i) => {
                                return (
                                    <li className={`list-content pr-md-4 ${anim(2 + i)}`} key={i}>
                                        <p>{list?.text}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </TabPane>
                    <TabPane id="cancellation-policy">
                        <ul className="list-2-column">
                            {data2?.list.map((list, i) => {
                                return (
                                    <li className={`list-content pr-md-4 ${anim(2 + i)}`} key={i}>
                                        <p>{list?.text}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </TabPane>
                </TabContent>
            </div>
        </section>
    )
}