import React from 'react'
import { useScrollAnim } from "src/components/hooks/hooks"


export const WFBIncluded = ({ data, className }) => {
    const [trigger, anim] = useScrollAnim()
    return (
        <section className={`py-main sc-wfb-included d-none d-md-block ${className}`} ref={trigger}>
            <div className="container">
                <h2 className={`font-weight-normal mb-4 ${anim(1)}`}>{data.title}</h2>
                <ul className="list-2-column">
                    {data.list.map((list, i) => {
                        return (
                            <li className={`list-content pr-md-4 ${anim(2 + i)}`} key={i}>
                                <p>{list.text}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </section>
    )
}