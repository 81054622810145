import React from "react"

import { Slider } from "components/anti/slider/slider"
import { Image } from "components/anti/image/image"
import { useScrollAnim } from "src/components/hooks/hooks"

export const WFBGallery = ({ data }) => {
    const [trigger, anim] = useScrollAnim()
    return (
        <section className="pb-sm-down-5 sc-wfb-gallery" ref={trigger}>
            <div className="container">
                <Slider
                    visibleInitial={true}
                    visibleXxlDown={true}
                    visibleXlDown={true}
                    visibleLgDown={true}
                    visibleMdDown={true}
                    visibleSmDown={true}
                    dotsInitial={false}
                    dotsXxlDown={false}
                    dotsXlDown={false}
                    dotsLgDown={false}
                    dotsMdDown={false}
                    dotsSmDown={false}
                    showInitial={2.6}
                    showXxlDown={2.6}
                    showXlDown={2.6}
                    showLgDown={2}
                    showMdDown={1.4}
                    showSmDown={1.4}
                    arrowsInitial={true}
                    arrowsXxlDown={true}
                    arrowsXlDown={true}
                    arrowsLgDown={true}
                    arrowsMdDown={false}
                    arrowsSmDown={false}
                >
                    {data.map((gallery, i) => {
                        return (
                            <Image src={gallery.sourceUrl} className={`img-fluid ${anim(1 + i)}`} ratio={"r-4-3"} alt="gallery" key={i} />
                        )

                        // if (i % 2 === 0) {
                        //     return (
                        //         <Image src={gallery} className="img-fluid" ratio={"r-4-5"} alt="gallery" key={i} />
                        //     )

                        // }
                        //     else if (i % 2 === 1) {
                        //         return (
                        //             <div key={i}>
                        //                 <Image src={gallery} className="img-fluid pb-3 mb-4" ratio={"r-16-9"} alt="gallery" />
                        //                 <Image src={data[i + 1]} className="img-fluid pt-3" ratio={"r-16-9"} alt="gallery" />
                        //             </div>
                        //         )
                        //     }
                    })}
                </Slider>
            </div>
        </section>
    )
}