import React from "react"

import Layout from "components/layout"
import { WFBDetailCover } from "../components/pages/wfb/detail/cover"
import { WFBPrice } from "../components/pages/wfb/detail/price"
import { WFBIncluded } from "../components/pages/wfb/detail/included"
import { WFBGallery } from "../components/pages/wfb/detail/gallery"
import { WFBIncludedMobile } from "../components/pages/wfb/detail/included-mobile"
import { WFBPriceMobile } from "../components/pages/wfb/detail/price-mobile"

const WFBDetails = ({ pageContext }) => {
    const pageData = pageContext.data.wfbDetail
    return (
        <Layout>
            <WFBDetailCover data={pageData.cover} />
            <WFBIncludedMobile data1={pageData.included} data2={pageData.cancellationPolicy} />
            <WFBIncluded data={pageData.included} />
            <WFBGallery data={pageData.gallery} />
            {/* <WFBPriceMobile data={pageData.price} /> */}
            <WFBIncluded data={pageData.cancellationPolicy} className="bg-light sc-wfb-cancellation" />
            <WFBPrice data={pageData.price} />
        </Layout>
    )
}

export default WFBDetails