import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Cover } from "components/anti/cover/cover"
import { Image } from "components/anti/image/image"

export const WFBDetailCover = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="sc-wfb-details-cover cover-full" ref={trigger}>
      <Cover
        variant="responsive"
        theme="dark"
        img={data.image.sourceUrl}
        imgHeight="r-16-9 h-md-500px"
        textClassName={`${anim(2)}`}
        titleClassName={`${anim(3)} font-weight-normal`}
        label={data.label}
        title={data.title}
        text={data.text}
        contentMaxWidth="mw-md-450px"
        className="cover-wfb-details animated fadeInUp d-none d-md-block"
      />
      <div className="d-block d-md-none">
        <Image src={data.image.sourceUrl} ratio="r-4-3" alt="Cover" />
        <div className="pt-4">
          <div className="container">
            <p className="caption">{data.label}</p>
            <h2>{data.title}</h2>
          </div>
        </div>
      </div>
    </section>
  )
}
